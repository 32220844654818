import React from 'react'
import TextCard from '~/components/molecules/TextCard'
import StoryPreview from '~/components/molecules/StoryPreview'
import Image from '~/components/atoms/Image'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function ImageUsps({key, index, ...props}) {

  const section = props.imageUsps

  return (
    <section id={section.fieldGroupName + index}  className={`my-20 md:my-36`}>
      <div className="container">
        <div className="max-w-xl mx-auto mb-20">
          <TextCard {...section.textCard}/>
        </div>
        <div className="grid grid-cols-12 gap-y-16 md:gap-12">
          {section.usps.map((item, i) => (
            <div key={`imageUsp${i}`} className={`col-span-12 md:col-span-4 ${(i + 1) % 2 === 0 && 'md:mt-24'}`}>
              <FadeInWhenVisible>
                <div className="aspect-w-4 aspect-h-5 mb-10">
                  <Image data={item.image} className="!absolute w-full h-full" />
                </div>
              </FadeInWhenVisible>
              <FadeInWhenVisible>
                <h6 className="text-h6 mb-3">{item.heading}</h6>
              </FadeInWhenVisible>
              <FadeInWhenVisible>
                <p>{item.content}</p>
              </FadeInWhenVisible>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
